/**/
.wrapper {
    width: 445px !important;
    height: 459px;

    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background-color: white;
}

.container {
    padding: 34px 28px 0px 28px;
}

.status {
    letter-spacing: 0px;
    text-align: center;
    margin-bottom: 32px;
    color: var(--main-black);
    font-size: 20px;
    font-weight: 500;
    height: 23px;
    letter-spacing: 0px;
    line-height: 23px;
}

.QRWrapper {
    position: relative;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    margin-bottom: 22px;
}

.timer {
    line-height: 22px;
    text-align: right;
    width: 100%;
    margin-bottom: 26px;
    color: var(--main-gray);
    font-size: 11px;
    font-weight: normal;
    height: 19px;
    letter-spacing: 0px;
    line-height: 22px;
}

.progressBar {
    position: absolute;
    top: 50%;

    left: 50%;
    transform: translate(-50%, -50%) rotate(-90deg);
    width: 350px !important;
}

.progressBar path {
    stroke-width: 3px;
}

.button {
    font-size: 13px;
    letter-spacing: 0px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    height: 65px;
}

.buttonWrapper {
    width: 100%;
}

.loader {
    padding-top: 94px;
    width: 458px;
    display: flex;
    justify-content: center;
    align-items: start;
}

.spinner {
    width: 330px;
    height: 330px;
    border-width: 8px;
}

.progressLine {
    height: 4px;
    background-color: var(--main-yellow);
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    display: block;
}

.progressLineWrapper {
    margin-bottom: 5px;
    width: 100%;
    height: 4px;
    background-color: var(--main-transparent-gray-2);
}

@media(min-width: 980px) and (max-width: 1400px) {
    .wrapper {
        height: 459px;
        width: 361px !important;
    }

    .spinner {
        height: 313px !important;
        width: 313px !important;
    }

    .loader {
        width: 361px !important;
        padding-top: 52px;
    }

    .status {
        font-size: 19px;
        margin-bottom: 30px;
    }


    .buttonWrapper {
        height: 60px;
    }

    .buttonWrapper button {
        height: 60px;
        font-size: 13px;
        font-weight: 600;
        letter-spacing: 0px;
        text-align: center;
        text-transform: uppercase;
    }
}

@media(max-width: 979px) {
    .wrapper {
        height: 428px;
        width: 100% !important;
    }

    .timer {
        margin-bottom: 0;
    }

    .container {
        padding: 24px 14px 0 14px;
    }

    .status {
        margin-bottom: 18px;
        text-align: left;
    }

    .QRWrapper {
        margin-bottom: -7px;
        margin-top: -20px;
    }
}

/*@media(max-width: 979px) {*/
/*    .spinner {*/
/*        width: 182px !important;*/
/*        height: 182px !important;*/
/*    }*/

/*    .wrapper {*/
/*        margin-right: 0;*/
/*        width: 100% !important;*/
/*        height: unset;*/
/*    }*/

/*    .status {*/
/*        color: rgb(46, 91, 255);*/
/*        font-size: 19px;*/
/*        font-weight: 600;*/
/*        letter-spacing: 0px;*/
/*        line-height: 23px;*/
/*        text-align: center;*/
/*        margin-bottom: 25px;*/
/*    }*/

/*    .buttonWrapper {*/
/*        display: none;*/
/*    }*/

/*    .timer {*/
/*        margin-bottom: 5px;*/
/*    }*/

/*    .loader {*/
/*        width: 100%;*/
/*        padding-top: 40px;*/
/*        padding-bottom: 70px;*/
/*    }*/



/*    .progressBar {*/
/*        display: none;*/
/*    }*/
/*}*/
