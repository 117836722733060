.wrapperMobile {
  display: flex;
  overflow: hidden;
  background: #00141b;
  border-radius: 0px;
  box-shadow: 0px 2px 22px 5px rgba(3, 13, 49, 0.32);
  height: 72px;
  z-index: 1050;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.closed {
  display: none;
}

.dFlexCol {
  flex-direction: column;
}

.dFlex,
.dFlexCol,
.displayCentered {
  display: flex;
}

.displayCentered {
  width: 100%;
  align-items: center;
}

.sidebarContent {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
}

.sideRelative {
  padding: 0px;
  position: relative;
}

.between {
  justify-content: space-between;
}

.px0 {
  padding-left: 0;
  padding-right: 0;
}

.navigation {
  height: 100vh;
  box-shadow: rgba(3, 13, 49, 0.32) 0px 2px 22px 5px;
  /*background-color: #030d31 !important;*/
  background-color: #00141b !important;
}

.toggle {
  text-align: left;
  padding-left: 13px !important;
  margin-bottom: 50px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.logo {
  max-height: 46px;
  height: 46px;
}

.logoWrapper {
  margin-bottom: 0px;
  margin-left: 24px;
  margin-top: 21px;
}

.gocrypto {
  /*background-color: #ffe700;*/
  margin-left: 0;
  margin-top: 0;
  height: 80px;
  min-height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gocrypto > .logo {
  max-width: 160px;
}

.gocrypto > .logoCollapsed {
  height: 24px;
}

.logoutWrapper:hover {
  cursor: pointer;
}

.icon {
  width: 18px;
  min-width: 18px;
  margin-right: 15px;
}

.linksContainer {
  padding-left: 0;
  padding-right: 0;
}

.callContainer {
  border-top: 1px solid rgba(127, 140, 141, 0.36);
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 15px;
}

.linkWrapper {
  padding-left: 19px;
  height: 56px;
  font-size: 14px;
}

.linkWrapper:last-child {
  margin-bottom: 0;
}

.linkWrapper.active {
  border-left: 2px solid #fced36;
  padding-left: 17px;
  background-color: rgba(63, 91, 190, 0.17);
}

.link {
  color: white;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}

.linkActive {
  color: #fced36;
}

.link:hover {
  color: #fced36;
  text-decoration: none;
  cursor: pointer !important;
}

.logout {
  background: none !important;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
  color: white;
  font-size: 12px;
  cursor: pointer;
  text-align: left;
}

.logoutWrapper {
  margin-top: auto;
  margin-bottom: 94px;
}

.logout:hover {
  color: #fced36;
}

.info {
  margin-left: 10px;
  margin-right: 10px;
  padding-left: 10px;
  padding-right: 10px;
  border-bottom: 1px solid rgba(127, 140, 141, 0.36);
}

.infoText {
  font-size: 13px;
  font-weight: normal;
  color: #7f8c8d;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.phoneNumber {
  color: rgb(219, 222, 222);
  font-size: 13px;
  font-weight: normal;
  letter-spacing: 0px;
}

.hideText > div {
  opacity: 0;
}

.info > div,
.callContainer > div {
  margin-bottom: 6px;
}

.info > div:last-child,
.callContainer > div:last-child {
  margin-bottom: 20px;
}

@media (min-width: 980px) and (max-width: 1400px) {
  .logoutWrapper {
    display: flex;
    margin-bottom: 55px !important;
  }
}

@media (max-width: 979px) {
  .container {
    display: flex;
  }

  .linkWrapper {
    padding: 0 !important;
    margin-bottom: 0 !important;
    height: 72px;
  }

  .linkWrapper,
  .logoutWrapper {
    display: flex;
    justify-content: center;
    margin: 0 5px;
    align-items: center;
  }

  .linkWrapper.active {
    padding: 0 !important;
    border: 0;
    background-color: transparent;
    border-top: 3px solid var(--main-yellow);
    border-bottom: 3px solid transparent;
  }

  .mobileLabel {
    color: rgb(176, 186, 201);
    font-size: 9px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 13px;
  }

  .linkActive .mobileLabel {
    color: #fced36 !important;
  }

  .logoutWrapper {
    display: flex;
    flex-direction: column;
  }

  .linkActive {
  }

  .link {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .link img,
  .logoutWrapper img {
    margin-right: 0;
    margin-bottom: 8px;
  }

  .logout {
    color: rgb(176, 186, 201);
    font-size: 8px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 13px;
  }
}
