
#sidebar, #content {
  height: 100%;
  transition: width .35s;
}

#sidebar {
  position: fixed !important;
  left:0;
  top:0;
  width: 217px;
  height: 100vh;
  z-index: 1000;
}

#sidebar.collapsed {
  width: 58px;
}

.sidebarLink {
  overflow: hidden;
  white-space: nowrap;
}

#sidebar.collapsed .sidebarLink span, #sidebar.collapsed .logout {
  display: none;
}
.logoCollapsed {
  display: none;
}
.logo {
  display: block;
}

#sidebar.collapsed .logoCollapsed {
  display: block !important;
}

#sidebar.collapsed .logo {
  display: none !important;
}


#sidebarContent {
  height: 100vh;
  overflow: hidden;
}

.hamburger:hover {
  opacity: 1 !important;
}
