/**/
.toolbar {
    padding: 15px;
    position: relative;
    background-color: white;
    height: 70px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 1px;
    margin-bottom: 0px;
    box-shadow: 0px 10px 20px 0px rgba(0, 20, 27, 0.07);
}

.dateExport {
    position: absolute;
    top: 485px;
    height: 65px;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.3px;
    margin-bottom: 100px;
    text-align: center;
    text-transform: uppercase;
    left: 0;
    width: 100%;
    box-shadow: 0px 10px 20px 0px rgba(0, 20, 27, 0.07);
    z-index: 1049;
}

.toolWrapper {
    display: flex;
    position: relative;
}

.dateSelectWrapper {
    position: relative;
}

.error {
    color: var(--main-red);
    font-size: 11px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 13px;
    position: absolute;
    top: -11px;
    padding: 4px 20px 4px 6px;
    background-color: white;
    left: 56px;
}

.closeButton {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 500;
}

.monthButton {
    margin-left: 15px;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0px;
    text-align: center;

}

.successfulPayments {
    display: flex;
    align-items: center;
    margin-left: 34px;
}

.successfulPaymentsLabel {
    font-size: 12px;
    font-weight: normal;
    color: var(--main-gray);
    letter-spacing: 0px;
    margin-left: 12px;
}

@media(max-width: 979px) {
    .monthButton {
        display: none;
    }
    .toolbar {
        padding: 10px 8px;
        height: 60px;
    }
    .dateExport {
        top: 473px;
    }
}

@media(max-width: 575px) {
    .exportLabel{
        display: none;
    }

    .successfulPayments {
        display: none;
    }
}

.exportLabel {
    margin-left: 10px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.3px;
    text-transform: uppercase;
}


.exportButton {
    position: relative;
    border-radius: 4px;
    padding-left: 15px;
    padding-right: 15px;
}

