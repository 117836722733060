/**/
.notice {
    margin-top: auto;
    display: flex;
    width: 100%;
    font-weight: normal;
    line-height: 22px;
    color: var(--main-light-gray);
    font-size: 11px;
    height: 13px;
}
