/**/
.titleRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.headerWrapper {
    display: flex;
}

.title {
    color: var(--main-black);
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0px;
}

.helpButton {
    height: 40px;
    margin-right: 20px;
}



.helpLabel {
    margin-left: 10px;
    font-size: 11px;
    font-weight: 500;
    height: 13px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
}

.revertIcon {
    width: 16px;
    height: 16px;
}

@media(max-width: 979px) {
    .helpLabel {
        display: none;
    }
}

