
td, th{
    vertical-align: middle !important;
    text-align: center;
}

table{
    border-collapse: collapse;
    overflow: hidden;
}

.table {
    border-top: 1px solid rgb(173, 179, 193);
    border-spacing: 0 10px;
    border-collapse: separate;
}

.thead{

    font-size: 14px !important;
    background-color: transparent;
    color: rgb(173, 179, 193);
}

.button{
    font-size: 12px !important;
    padding: 0 10px 0 10px;
    border-radius: 12px;
    text-align: center;
    width:105px;
    text-transform: uppercase;
}
.button:hover{
    cursor: initial !important;
}

.print-button{
    width: 132px;
    height: 32px;
    min-width: 80px;
    background-color: transparent;
    border-radius: 3px;
    color: rgb(138, 164, 255);
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 1.12px;
    line-height: 12px;
    text-align: center;
    text-transform: uppercase;
}

.print-button:hover{
    background-color: transparent;
    color: #3db3c3;
    border-color: #3db3c3;
}

.icon{
    height: 17px;
    width: 17px;

    margin-right: 10px;
}

.icon path {
    color: rgb(138, 164, 255);
}


td{
    background-color: white;
    color:#777;
    text-align: left;
    font-size:14px;
    border: 0;
}

.thead th{
    line-height: 18px;
    text-align: left;
    padding: 15px 28px 25px 28px;
    word-break: keep-all;
    border: 0 !important;
    color: rgb(173, 179, 193);
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1.12px;
    text-transform: uppercase;
}



tr{
    margin-bottom: 5px;
    border: 0;
}

td:first-child{
    padding-right: 0 !important;
    text-align: left;
}

@media print {
    .print-button{
        display: none;
    }
    .button{
        background-color: transparent;
        border: 0;
        font-size:15px !important;
        margin:0 !important;
        padding:0 !important;
    }

}

.table-id {
    padding-left: 25px;
    border-left: 4px solid;
}

.time-border{

}

@media (max-width: 991px) {
    .time-border{
        border-left: 4px solid;
        padding-left:25px;
    }


}

.danger{
    border-left-color:#ea8484 !important;
}

.success{
    border-left-color: #4fa053 !important;
}

.gray{
    border-left-color: lightgray !important;
}

.print-div {
    font-size: 22px;
    width: 100%;
    text-align: left !important;
}
.print-div > div{
    margin-bottom: 10px;
}

/*.print-div > div:first-child{
    margin-top: 20px;
    width:150px;
}*/

.revert {
    border-radius: 4px;
    font-size:14px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border:0;
    background-color: #faeaeb;
}

.revert:hover, .revert:focus {
    background-color: #faeaeb;
}

.revert:disabled, .revert:disabled:focus, .revert:disabled:hover {
    background-color: #dfdfdf;
    opacity: 0.5;
}

.revert img {
    width: 15px;
    height: 15px;
}

.table {
    word-break: break-word;
}

.print-logo {
    margin-bottom:5mm;
}

.print-logo>img{
    /*height: 14mm !important;*/

    width:75%;
}

/*font-family: "Averta Bold" !important;*/

.print-text-1{

    font-family: Helvetica !important;
    font-size:10pt;
    line-height: 12pt;
}

/*font-family: "Averta Semibold";*/
.print-text-2{

    font-family: Helvetica !important;
    font-size:8pt;
    line-height: 10pt;
}
/*font-family: "Averta Regular";*/
.print-text-3{

    font-family: Helvetica !important;
    font-size:8pt;
    line-height: 10pt;
}


.print-info {
    margin: 0 0 3mm 0 !important;
}

.print-status>button {
    font-size:22px !important;
    padding-left:0;
}

.print-status{
    margin: 0 0 5mm 0 !important;
    padding:0;
}



.print-div{
    padding:5mm 5mm 15mm 5mm;
    /*padding:0mm 0mm 0mm 0mm;*/
    justify-content: center;
    flex-direction: column;
    position: absolute;
    top:-100000px;
}

/*top:-10000px;*/

@media print {
    .print-div{
        position: relative !important;
        top:0 !important;
    }
}

.print-field{
    margin: 0 0 3mm 0 !important;
}

.print-wrapper {
    /*width:58mm;*/
    /*font-family: Tahoma !important;*/
}

.hidden {
    height:0;
}

.tbody {

}

.tbody td{
    padding: 20px 28px;
    border: 0;
    color: rgb(3, 13, 49);
    font-size: 15px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 22px;
}

td:last-child{
    text-align: right !important;
    padding-right: 24px;
}

th:last-child{
    padding-right: 24px;
}

.reference {
    text-overflow: clip;
    max-width: 9ch;
}

.status {
    width:32px;
    height: 32px;
}


@media (max-width: 767px) {
    .thead th, .tbody td {
        font-size: 12px !important;
        line-height: 14px;
    }

}

.button-status {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: normal;
    height: 32px;
    letter-spacing: 1.12px;
    line-height: 14px;
    text-align: center;
    text-transform: uppercase;
    width: 132px;
    border-radius: 4px;
    border: 0;
}

.status-red {
    background-color: #faeaeb;
    color: rgb(232, 74, 80);
}

.status-green {
    background-color: #e6f5e5;
    color: rgb(51, 172, 46);
}
.status-blue {
    background-color: #ebeffc;
    color: rgb(46, 91, 255);
}
.status-orange {
    background-color: #ffefe4;
    color: rgb(255, 101, 0);
}

@media (min-width: 980px) and (max-width: 1399px) {
    .print-button {
        width: 55px;
        max-width: 55px;
        padding-left: 0;
        padding-right: 0;
        min-width: 55px;
    }

    .print-button img {
        margin: 0;
    }

    .table td {
        padding-left: 20px;
        padding-right: 20px;
    }

    .table td:first-child {
        padding-left: 24px;
    }

    .table td:last-child {
        padding-right: 24px;
    }
}


@media (max-width: 979px) {
    .button-status {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        font-weight: normal;
        height: 28px;
        letter-spacing: 0.93px;
        line-height: 11px;
        text-align: center;
        text-transform: uppercase;
        width: 83px;
        border-radius: 4px;
        border: 0;
    }

    .table td {
        padding-left: 10px;
        padding-right: 10px;
    }

    .table th {
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 20px;
        padding-top: 0;
    }

    .print-button {
        width: 50px;
        min-width: 50px;
        padding-left: 0;
        padding-right: 0;
        margin: 0;
    }

    .print-button img {
        margin: 0;
    }
}
