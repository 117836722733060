.wrapper {
    background-color: white;
    border-radius: 1px;
    border: 1px solid rgba(46, 91, 255, 0.08);
    height: 459px;

    width: 445px;
    /*box-shadow: rgba(46, 91, 255, 0.07) 0 10px 20px 0;*/

    margin-right: 30px;

    display: flex;
    flex-direction: column;
}

.padding {
    padding: 20px 24px 0px 24px;
}

.amount {
    border-radius: 5px;
    margin-bottom: 38px;
}

.regular {
    /*font-family: "Maax-Regular" -apple-system, "Ubuntu", sans-serif !important;*/
}

.label {
    color: var(--main-black);
    font-size: 20px;
    font-weight: 500;
    height: 23px;
    letter-spacing: 0px;
    line-height: 23px;
    width: 231px;
}

.divider {
    color: #030d31;
    margin-bottom: 28px;
    margin-top: 24px;
}

.inputWrapper {
    position: relative;
    display: flex;
    height: 50px;
    margin-bottom: 10px;
}

.currency {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 38px;
}




.euroValue {
    color: var(--main-gray);
    font-size: 11px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 15px;
    height: 24px;
}

.error {
    color: var(--main-red);
    font-size: 11px;
    font-weight: normal;
    height: 24px;
    letter-spacing: 0px;
    line-height: 15px;
}

.input {
    color: var(--main-black);
    font-size: 38px;
    font-weight: normal;
    height: 50px;
    padding-top: 0;
    padding-bottom: 0;
    overflow: hidden;
    letter-spacing: -0.6px;
    padding-left: 20px;
    background: transparent;
    border: 0;
    caret-color: var(--main-blue);
    line-height: normal;
}

.input:focus {
    background: transparent;
    border: 0;
}

.input::placeholder {
    color: #A7ACBF;
}

.input:disabled {
    color: var(--main-black) !important;
    -webkit-text-fill-color: var(--main-black);
    opacity: 1; /* required on iOS */
}

.helpButton {
    font-size: 11px !important;
    font-weight: 500;
    letter-spacing: 0.3px;
    text-transform: uppercase;
}





@media(min-width: 980px) and (max-width: 1400px) {
    .wrapper {
        width: 355px;
        height: 459px;
        margin-right: 20px;
    }

    .padding {
        padding: 24px;
    }

    .amount {
        border-radius: 5px;
        margin-bottom: 24px;
    }

    .label {
        color: rgb(3, 13, 49);
        font-size: 19px;
        letter-spacing: 0px;
    }

    .divider {
        margin-bottom: 10px;
    }

    .currency {
        font-size: 34px;
        line-height: 34px;
        padding-top: 0;
    }

    .input {
        font-size: 34px;
        height: 50px;
        padding-top: 0;
        padding-bottom: 0;


    }

    .input::-webkit-input-placeholder {
        text-align: left;
        vertical-align: middle;
        padding-top: 4px;
    }
}

.mobileHelp {
    display: none !important;
}

.helpLabel {
    margin-left: 10px;
}

.submitButton {
    display: block !important;
    width: 100%;
    height: 65px !important;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    text-align: center;
    text-transform: uppercase;
    font-size: 13px !important;
    font-weight: 500 !important;
    letter-spacing: 1px;
    text-align: center;
    text-transform: uppercase;
}

@media (max-width: 979px) {
    .wrapper {
        margin-right: 0;
        width: 100%;
        display: flex;
        height: 296px;
        flex-direction: column;

    }


    .helpLabel {
        display: none;
    }

    .divider {
        margin-bottom: 15px !important;
    }

    .padding {
        padding: 15px 15px 0 15px;
    }

    .label {
        color: rgb(46, 56, 77);
        font-size: 19px;
        font-weight: 600;
        height: 24px;
        letter-spacing: 0px;
        line-height: 23px;
        margin-bottom: 0;
    }

    .amount {
        margin-bottom: 15px;
    }

    .mobileHelp {
        display: block !important;
        background-color: transparent;
        padding: 7px 13px;
    }

    .mobileHelp img {
        margin-right: 0;
    }

    .divider {
        margin-top: 13px;
        margin-bottom: 25px;
    }

    .inputWrapper {
        height: 41px;
    }

    .input {
        height: 41px;
        padding-top: 0;
        padding-bottom: 0;
        font-size: 34px;
        font-weight: normal;
        letter-spacing: 0px;
        line-height: 41px;
        width: 222px;
        padding-left: 20px;
    }

    .currency {
        font-size: 34px;
        padding-top: 0;
    }

    .submitButton {

    }

    .notice {
        color: rgb(162, 170, 187);
        font-size: 11px;
        font-weight: normal;
        margin-top: 130px;
        letter-spacing: 0px;
        line-height: 15px;
    }
}

/*               SAFARI HACKS               */
@media not all and (min-resolution:.001dpcm) {

    @media(min-width: 586px) and (max-width: 979px) {
        .input::-webkit-input-placeholder {
            text-align: left;
            vertical-align: middle;
            line-height: normal;
        }

        .currency {
            padding-top: 5px !important;
        }
    }



    @media(min-width: 1400px) {
        .currency {
            padding-top: 5px !important;
        }
    }
    @media(min-width: 980px) and (max-width: 1400px) {
        .currency {
            padding-top: 0 !important;
        }
        .input {
            line-height: 1.2;
        }
    }


    @media(max-width: 585px) {
        .currency {
            padding-top: 5px !important;
        }
        .input::-webkit-input-placeholder {
            text-align: left;
            vertical-align: middle;
            line-height: normal;
        }
    }
}
