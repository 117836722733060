/**/

.loader {
    opacity: 0.2;
    background-color: white;
}

.printPreview {
    position: absolute;
    top: -9000px;
    left: -9000px;
}

@media print {
    .printPreview {
        top: 0 !important;
        left: 0 !important;
    }
}

.table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0px 6px;
    margin-bottom: 25px;
}

.headerRow {
    margin-bottom: 19px;
}

.headerCell {
    line-height: 40px;
    text-align: left;
    color: var(--main-gray);
    font-size: 11px;
    font-weight: 500;
    height: 13px;
    letter-spacing: 0.28px;
    text-transform: uppercase;
}

.cell {
    text-align: left;
    color: rgb(0, 20, 27);
    font-size: 13px;
    font-weight: normal;
    letter-spacing: 0px;
}


.cell:last-child > button, .headerCell:last-child{
    text-align: right !important;
    margin-left: auto;
}

.headerCell {
    padding: 0 15px 0 15px;
}

.cell{
    padding: 0 15px 0 15px;
}

.row {
    height: 67px;
    margin-bottom: 6px;
}

.status {
    width: 138px;
}

.emptyMessage {
    font-size: 13px;
    font-weight: normal;
    color: var(--main-light-gray);
    width: 100%;
    text-align: center;
    letter-spacing: 0px;
    text-align: center;
}

.print-container {
    z-index: -1;
}
