/**/

@media(min-width: 980px) {
    .wrapper {
        display: none !important;
    }
}


.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.currency {
    color: var(--main-black);
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 34px;
    display: flex;
    align-items: flex-end;
    margin-right: 10px;
    padding-bottom: 8px;
}

.amount {
    color: var(--main-black);
    font-size: 34px;
    font-weight: normal;
    letter-spacing: 0px;
    display: flex;
    align-items: flex-end;
}

.label {
    color: var(--main-black);
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 23px;
    text-align: center;
}

.container {
    display: flex;
}

.euroAmount {
    color: var(--main-gray);
    font-size: 11px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 13px;
    text-align: center;
    margin-bottom: 24px;
}
