.wrapper {
    width: 141px;
    max-width: 138px;
    height: 88px;
    justify-content: space-between;
    margin-right: 12px;
    margin-bottom: 10px;
    border-radius: 3px;
    outline: none;
    box-shadow: none !important;
    border: none;



    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--main-black);
    font-size: 32px;
    font-weight: normal;
    letter-spacing: -0.6px;
    text-align: center;
    background-color: white;
}



.wrapper:focus {
    outline: none;
    box-shadow: none !important;
    color: rgb(3, 13, 49);
}

.wrapper:hover, .wrapper.active {
    cursor: pointer;
    background: var(--main-transparent-gray-2) !important;
    box-shadow: none !important;
    color: var(--main-black) !important;
}

.wrapper:nth-child(3), .wrapper:nth-child(6), .wrapper:nth-child(9), .wrapper:nth-child(12){
    margin-right: 0;
}

@media(min-width: 980px) and (max-width: 1400px) {
    .wrapper {
        width: 117px;
        height: 88px;
        margin-right: 10px;
        margin-bottom: 10px;

    }

    .wrapper div {
        font-size: 32px !important;
    }
}
