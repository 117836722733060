/**/
.wrapper {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--main-black);
    color: white;
    top: 0;
    z-index: 999999;

}

.progressBar {
    transition: width 500ms linear;
}

.logo {
    margin-bottom: 50px;
    width: 135px;
}

.container {
    width: 160px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loadingBar {
    background-color: #14323d;
    height: 4px;
    width: 100%;
    border-radius: 5px;
    position: relative;
}

.progressBar {
    position: absolute;
    top: 0;
    left: 0;
    height: 4px;
    border-radius: 5px;
    background-color: var(--main-yellow);
    width: 50%;
}
