.wrapper {
    width: 100vw;
    height: 100vh;
    background-color: var(--main-black);
    display: flex;
    top: 0;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    position: fixed;
    z-index: 10000;
}

.maintenance {
    width: 264px;
    margin-bottom: 30px;
}

.logo {
    width: 173px;
    margin-bottom: 25px;
}

.message {
    color: var(--main-gray);
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 23px;
    text-align: center;
    width: 303px;
}
