.mainWrapper{
    max-width: 730px;
}
.fullContainer
{
    width: 100%;
}
.wrapper, .totalWrapper
{
    width: 100%;
    border-radius: 1px;
    color: var(--main-black);
    font-size: 13px;
    letter-spacing: 0px;
    padding: 15px;
    display:flex;
    justify-content: center;
}

.wrapper
{
    font-weight: normal;
    height: 67px;
    background-color: white;
    border: 1px solid white;
    margin-top: 4px;
}
.totalWrapper
{
    background-color: var(--main-transparent-gray-2);
    border: 1px solid var(--main-transparent-gray-2);
    font-weight: bold;
    height: 46px;
    margin-top: 6px;
}
.loader
{
    height: 350px;
    display: flex;
}
.noTransactionWrapper
{
    height: 54px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 4px;
}
.labelContainer
{
    display:flex;
    justify-content: space-between;
    padding: 15px;
    margin-top: 27px;
}
.label
{
    border: 0;
    color: var(--main-gray);
    font-size: 11px;
    font-weight: 500;
    height: 13px;
    letter-spacing: 0.28px;
    text-transform: uppercase;
}
.title {
    color: var(--main-black);
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0px;
}
.item:last-child{
    margin-bottom: 0;
}

.topPart {
    display: flex;
    justify-content: space-between;
}

.disabledPrint
{
    background:  rgb(229, 232, 232) !important;
    border-color: rgb(229, 232, 232) !important;
}
.printNone
{
    display: none !important;
}
.printIcon
{
    height: 16px;
    width: 16px;
}
.printButton
{
    width: 108px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.printLabel
{
    color:white;
    font-size: 11px;
    font-weight: 500;
    margin-left: 10px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
}
.totalRow
{
    width: 100%;
    font-weight: bold;
}
.bottom, .totalRow, .top
{
    display: flex;
    justify-content: space-between;
}
.bottom
{
    font-weight: bold;
}
.top
{
    text-align: right;
}
.message
{
    color: rgba(127, 140, 141, 0.7);
    font-size: 13px;
    font-weight: normal;
    height: 16px;
    letter-spacing: 0px;
}
.line
{
    background: rgb(255, 255, 255);
    border-radius: 1px;
    height: 2px;
    width: 745px;
}
@media print
{
    .disabledPrint
    {
        display:none;
    }
    .total
    {
        margin-bottom: 0;
    }

    .wrapper
    {
        width: 50%;
        margin: 0 !important;
    }

    .container
    {
        width: 210mm !important;
        display: flex !important;
        justify-content: center !important;
        padding: 0;
    }
}

/*
    TABLET STYLING
*/
@media (min-width: 980px) and (max-width: 1400px)
{
    .mainWrapper
    {
        width: 100%;
    }

    .line{
        width: 100%;
    }
}

/*
    MOBILE STYLING
*/
@media (max-width: 979px)
{
    .topPart
    {
        justify-content: space-between;
    }
    .printButton
    {
        width: 110px;
    }

    .printLabel
    {
        font-size: 12px;
    }
    .message
    {
        font-size: 12px;
        font-weight: normal;
        height: 15px;
        letter-spacing: 0px;
        line-height: 16px;
        text-align: center;
        width: 158px;
    }
    .transactionWrapper
    {
        display: flex;
        justify-content: center;
    }
    .noTransactionWrapper
    {
        height: 42px;
        width: 100%;
        margin-top: 0px;
    }
    .label
    {
        font-size: 10px;
        letter-spacing: 0.25px;
        line-height: 14px;
        width: auto;
    }
    .labelContainer
    {
        display:flex;
        justify-content: space-between;
        margin-top: 15px;
    }
    .line
    {
        width: 100%;
    }
    .mainWrapper
    {
        width: 100%;
        margin-top: -10px;
        justify-content: center;
    }
    .wrapper
    {
        font-size: 12px;
        padding: 10px;
        font-weight: normal;
        height: 50px;

    }
    .top, .bottom, .totalRow
    {
        font-size: 12px;
    }
    .totalWrapper
    {
        margin-top: 5px;
        height: 40px;
    }
    .title
    {
        font-size: 22px;
        height: 29px;
        letter-spacing: 0px;
        width: 186px;
    }
}
