.header {
    background-color: #ebeffc;
    height: 80px;
    display: flex;
    align-items: center;
    margin-bottom: 25px;
}

.lightbulb {
    width: 20px;
    height: 20px;
    margin-right: calc(1rem * 0.5);
}

.gocrypto {
    max-width: 120px;
    width: 120px !important;
}

.helpButton {
    margin-right: 36px;
}

.text {
    font-size: 15px;
    font-weight: normal;
    color: rgb(3, 13, 49);
    line-height: 22px;
    letter-spacing: 0;
    margin-right: 18px;
}

.phoneIcon {
    margin-right: 8px;
}

.phoneNumber {
    font-size: 15px;
    font-weight: normal;
    color: rgb(3, 13, 49);
    line-height: 22px;
    letter-spacing: 0.8px;
}

.shopWrapper {
    background-color: #dae2ff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42px;
    height: 42px;
}

.dFlex
{
    display: flex;
}

.dFlexCenter
{
    display: flex;
    align-items: center;
}

.between
{
    justify-content: space-between;
}
.shopWrapper img {
    width: 24px;

}

@media(min-width: 980px) and (max-width: 1400px) {
    .helpButton {
        margin-right: 54px;
    }

}

.mobileHeader {
    display: none;
}

@media(max-width: 979px) {
    .mobileHeader {
        display: block;
        margin-bottom: 25px;
        height: 72px;
        background: var(--main-black);
        padding-left: 15px;
        padding-right: 15px;
    }
}



.logo {
    width: 65px;
    margin-bottom: 3px;
}

.mobileHeader > div {
    height: inherit;
}

.phoneNumber a{
    color: rgb(255, 255, 255) !important;
    font-size: 13px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 15px;
    text-align: right;
}

@media (max-width: 979px) {
    .phoneNumber {
        margin-bottom: 5px;
    }

    .mobileShop {
        color: rgb(167, 172, 191);
        font-size: 13px;
        font-weight: normal;
        letter-spacing: 0px;
        line-height: 15px;
        text-align: right;
    }

}

