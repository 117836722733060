.logoWrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 28px;
}

.logo {
    width: 174px;
}

.title {
    letter-spacing: 0px;
    margin-bottom: 25px;
    color: rgb(0, 20, 27);
    font-size: 18px;
    font-weight: 500;
    height: 23px;
    line-height: 23px;
    width: 151px;
}

.formWrapper {
    display: flex;
    flex-direction: column;
}

.form {
    width: calc(100% + 1px);
}

.fieldWrapper {
    margin-bottom: 20px;
}
.fieldWrapper > label {
    margin-bottom: 5px;
}


.inputFields {
    margin-bottom: 0px;
    padding: 0 26px 0 26px;
}

.loginButton {
    width: calc(100% - 1px) !important;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.3px;
    text-align: center;
    text-transform: uppercase;
}

.input {
    /*background: rgb(249, 251, 254);*/
    background: transparent;
    border-radius: 4px;
    border: 1px solid var(--main-transparent-gray-2);
    height: 50px;
    padding-left: 60px;
    color: var(--main-black);
    font-size: 15px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 13px;
}

.input::-webkit-input-placeholder {
    font-size: 14px;
    color: #d0cdfa;
    text-align: left;
    vertical-align: middle;
    padding-top: 4px;
}

.error {
    position: absolute;
    top: -6px;
    z-index: 99999999;
    left: 0;
    padding-left: 5px;
    padding-bottom: 5px;
    background-color: white;
    padding-right: 5px;
    color: var(--main-red);
    font-size: 11px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 13px;
}

.inputWrapper {
    position: relative;
}

.decoratorBefore {
    left: 26px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.decoratorAfter {
    right: 17px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.clickable:hover {
    cursor: pointer;
}

.decoratorText {
    margin-right: 15px;
    font-weight: normal;
    line-height: 16px;
    color: var(--main-gray);
    font-size: 10px;
    height: 13px;
    letter-spacing: 0px;
    text-align: right;
}

.input::placeholder {
    color: var(--main-gray);
    font-size: 15px;
    font-weight: normal;
    letter-spacing: 0px;

    height: 13px;
    line-height: 13px
}

.modal {
    margin-top: 192px;
    border: 1px solid rgba(46,91,255,0.08);
    width: 355px;

}

.modalBody {
    padding: 24px 0 0 0;
    border-radius: 0;
    background-color: white;
}

.modalWrapper {
    background: black;
    z-index: 100000;
}

.modalContent {
    border-radius: 0;
    background-color: transparent;
}


/* TABLET STYLING */
@media(min-width: 980px) and (max-width: 1024px) {
    .modal {
        width: 610px;
        margin-top: 236px;
    }

    .inputFields {
        margin-bottom: 24px;
    }


    .title {
        margin-bottom: 24px;
    }
}

/* MOBILE STYLING */
@media(max-width: 979px) {
    .modalWrapper {
        padding: 0;
        max-width: 100vw;
    }

    .modal {
        width: calc(100vw);
        margin-top: 125px !important;
        padding-left: 15px;
        padding-right: 15px;
        margin: auto;
    }


    .title {
        color: rgb(1, 14, 62);
        font-size: 23px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 28px;
        margin-bottom: 57px;
    }
}
