.pos-container {
  margin-top: 39px;
  min-height: calc(100vh - 240px);
}

.payment-container {
  min-height: calc(100vh - 270px);
}
.container {
  margin-bottom: 150px;
}

@media (min-width: 1401px) {
  .container {
    padding-left: 0 !important;
    padding-right: 0 !important;
    max-width: 920px !important;
    margin-left: calc(215px + ((100vw - 215px) - 920px)/2) !important;
  }
}

@media(min-width: 980px) and (max-width: 1400px) {
  .container {
    max-width: 746px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: calc(215px + ((100vw - 215px) - 746px)/2) !important;
  }

  .error {
    font-size: 15px;
    line-height: 14px;
    padding-bottom: 14px;
  }
  .pos-container {
    margin-top: 19px;
    min-height: calc(100vh - 210px);
  }
}

@media(max-width: 979px) {
  .pos-container {
    margin-top: 15px;
    min-height: calc(100vh - 90px);
  }
}



