@import url('https://fonts.googleapis.com/css?family=Noto+Sans&display=swap');
@import "./global-styling/container.scss";
@import "./global-styling/sidebar.scss";
@import "./global-styling/colors.scss";
@import "./global-styling/datepicker.scss";
@import "./global-styling/status.scss";

@font-face {
  font-family: Maax-Regular;
  src: url("./fonts/Maax-205TF/Maax-Regular-205TF/Maax-Regular-205TF.woff");
  font-weight: normal;
}

@font-face {
  font-family: Maax-Medium;
  src: url("./fonts/Maax-205TF/Maax-Medium-205TF/Maax-Medium-205TF.woff");
  font-weight: normal;
}


body {
  margin: 0;
  font-family: "Maax-Regular", "Noto Sans", -apple-system, "Ubuntu", sans-serif;
  background-color: var(--main-transparent-gray-4) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  height: 100%;
  width: 100%;
  overflow: auto;
}
body, html { position: fixed; }

@media(max-height: 450px) {
  html,body {
    height: unset;
    top: 0;
    bottom: 0;
  }
}

button:focus {
  outline: none !important;
}

button:hover {
  cursor: pointer !important;
}

a:hover {
  cursor: pointer;
}

input:focus {
  outline: none !important;
  /*border-color: inherit !important;*/
  box-shadow: none !important;
}

input {
  caret-color: #2e5bff;
}
#printFrame {
  z-index: -1;
  position: fixed;
  left: -1000px;
}

.loader {
  justify-content: center;
  display: flex;
  align-items: center;
  margin: auto;
}

.loader-app {
  height: calc(100vh - 180px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  height: 40px;
  animation-name: spin;
  animation-duration: 1900ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}

.form-control:disabled {
  background-color: transparent !important;
}

.between-centered {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.error {
  color: red;
  font-size: 15px;
  line-height: 14px;
  padding-bottom: 14px;
}

body .mobile-only {
  display: none !important;
}

body .mobile-hidden {
  display: initial !important;
}

body td.mobile-hidden, body th.mobile-hidden {
  display: table-cell !important;
}


@media(max-width: 979px) {
  body .mobile-only {
    display: flex !important;
  }

  body .mobile-hidden, body th.mobile-hidden, body td.mobile-hidden {
    display: none !important;
  }
}

/* Mozilla override */
input:invalid {
  box-shadow: none;
}

input:-moz-submit-invalid {
  box-shadow: none;
}

input:-moz-ui-invalid {
  box-shadow:none;
}




.modal {
  z-index: 99999;
}

.card-shadow {
  box-shadow: 0px 10px 20px 0px rgba(0, 20, 27, 0.07);
}
