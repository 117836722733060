/**/

.container {
    vertical-align: middle;
    display: flex;
    align-items: center;
}

.checked {
    background-color: yellow !important;
}

.checkbox {
    border: 1px solid rgb(219, 222, 222);
    width: 16px;
    height: 16px;
    border-radius: 3px;
    transition: all 150ms;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 2px;
}

.checkbox:hover {
    cursor: pointer;
}

.checkboxHidden {
    border: 0;
    clip: rect(0 0 0 0);
    clippath: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}
