.wrapper {
    width: 100%;
}

.title
{
    color: var(--main-black);
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0px;
    margin-bottom: 24px;
}


.collapsible {
    background: rgb(255, 255, 255);
    border-radius: 1px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 15px 0px 15px 15px;
    margin-bottom: 0;
    align-items: baseline;
    min-height: 67px;
    color: var(--main-black);
    font-size: 15px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 22px;
}


.QAWrapper
{
    margin-top: 24px;
}
.collapsibleWrapper {
    margin-bottom: 5px;
}

.answer {
    padding:  0px 15px 0px 15px;
    color: rgb(46, 56, 77);
    border-radius: 1px;
    width: 100%;
    color: var(--main-black);
    font-size: 15px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 22px;
    overflow-y: auto;
}

.answer >p:last-child {
    margin-bottom: 26px;
}
.answer > p:first-child {
    margin-top: 10px;
}


.button, .button:focus, .button:hover {
    background: transparent !important;
    border: 0 !important;
    box-shadow: none !important;
}

@media (max-width: 979px) {

    .title
    {
        font-size: 23px;
    }
}