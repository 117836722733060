/**/
.container {
    display: flex;
    justify-content: flex-end;
    color: var(--main-gray);
    font-size: 11px;
    font-weight: normal;
    letter-spacing: 0.28px;
    text-align: center;
    text-transform: uppercase;
    align-items: center;
}

.arrows {
    display: flex;
    margin-left: 15px;
}

.leftArrow {
    margin-right: 10px;
}
