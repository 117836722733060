.wrapper {
    display: flex;
    margin-bottom: 24px !important;
}

.label {
    line-height: 22px;
    color: var(--main-transparent-gray-1);
    font-size: 13px;
    font-weight: 500;
    height: 16px;
}

.active {
    color: var(--main-gray) !important;
}

.segment {
    position: relative;
}

.stepWrapper:nth-child(2) hr {
    width: 50%;
    margin: 0 !important;
    display: flex;

}

.segment hr {
    border: 0;
    margin: 0 !important;
}

.stepWrapper:nth-child(2) .segment {
    display: flex;
}


.line {
    height: 3px;
    background-color: #eff2fd;
}

.lineInactive {
    height: 3px;
    background-color: #eff2fd !important;
}

.circle {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 4px solid #e9eeff;

    border-radius: 50%;
    background-color: white;
    width: 16px;
    height: 16px;
}

.active > .circle {
    background-color: #fced36;
    width: 24px;
    height: 24px;
    border: 6px solid rgb(3, 13, 49);
}

.active > .line {
    background-color: #030d31;
}

.stepWrapper:first-child{
    flex-grow: 1;
    text-align: left;
}
.stepWrapper:last-child{
    flex-grow: 1;
    text-align: right;
}

.stepWrapper {
    flex-grow: 2;
    text-align: center;
}

.stepWrapper:first-child  .circle {
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.stepWrapper:last-child  .circle {
    left: 100%;
    top: 50%;
    transform: translate(-100%, -50%);
}


.mobileWrapper {
    justify-content: space-between;
    color: rgb(3, 13, 49);
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0.3px;
    margin-bottom: 25px;
    width: 100%;
    display: none;
    margin-bottom: 14px;
}

@media(max-width: 980px) {
    .mobileWrapper {
        display: flex;
    }

    .wrapper {
        display: none;
    }
}
