/**/
.wrapper {
    width: 445px !important;
    height: 459px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background-color: white;
}

.status {
    font-size: 20px;
    font-weight: 500;
    height: 23px;
    letter-spacing: 0px;
    line-height: 23px;
    margin-bottom: 0px;
}

.statusDescription {
    color: rgb(0, 20, 27);
    -webkit-text-fill-color: initial;
    font-size: 12px;
    font-weight: normal;
    height: 13px;
    letter-spacing: 0px;
    line-height: 13px;
    width: 379px;
    margin: 0;
    margin-bottom: 60px;
}

.container {
    padding: 34px 27px 0px 27px;
}

.divider {
    margin-top: 32px;
    margin-bottom: 80px;
}

.underpaid{
    margin-top: 32px;
    margin-bottom: 16px;
}

.bigMargin{
    margin: 80px 0;
}

.iconWrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100% !important;
    height: 125px;
}



.button {
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    height: 65px;
    color: var(--main-black);
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.3px;

}

.buttonWrapper {
    width: 100%;
}

@media(min-width: 980px) and (max-width: 1400px) {
    .wrapper {
        width: 369px !important;
        padding-top: 0;
        height: 459px;
    }

    .status {
        font-size: 19px;
        margin-bottom: 30px;
    }

    .timer {
        margin-bottom: 32px;
        font-size: 19px;
    }

}

@media (max-width: 979px) {
    .wrapper {
        height: unset;
        padding-top: 5px;
        margin-right: 0;
        width: 100% !important;
        margin-bottom: 75px;
    }

    .container {
        padding: 20px 15px 0 15px;
    }

    .status {
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0px;
        line-height: 23px;
        text-align: center;
        margin-bottom: 18px;
    }

    .divider {
        margin-top: 0px;
        margin-bottom: 50px;
    }

    .iconWrapper {
        margin-bottom: 40px;
    }
}
