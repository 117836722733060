/**/

.bar {
    height: 10px;
    background-color: var(--main-yellow);
    transition: width 1s ease;
    margin-bottom: 70px;
}

.barWrapper {
    width: 100%;
}

.wrapper {
    width: 100%;
    display: flex;
}

.padding {
    width: 217px;
    height: 10px;
    background-color: var(--main-yellow);

}

@media(max-width: 980px) {
    .wrapper {
        display: none;
    }
}
