/**/
.wrapper {
    height: 50px;
    position: relative;
}

.input {
    height: 50px;
    border-radius: 5px;
    border: 1px solid var(--main-transparent-gray-2);
    padding-left: 65px;
    color: var(--main-black);
    font-size: 15px;
    font-weight: normal;
    letter-spacing: 0.3px;
    line-height: 22px;
}



.disabled input {
    background-color: transparent;
}


.inputIcon {
    position: absolute;
    left: 24px;
    top: 46%;
    transform: translateY(-50%);
}

.input::placeholder {
    color: var(--main-gray);
    font-size: 13px;
    font-weight: normal;
    height: 13px;
    letter-spacing: 0px;
    line-height: 13px;
}

.input:disabled {
    color: var(--main-gray) !important;
    -webkit-text-fill-color: var(--main-gray);
    opacity: 1; /* required on iOS */
}

.input::-webkit-input-placeholder {
    text-align: left;
    vertical-align: middle;
    padding-top: 4px;
}


@media(min-width: 980px) and (max-width: 1400px) {
    .input {
        font-size: 13px;
        letter-spacing: 0.33px;
    }

    .inputIcon img{
        width: 16px;
        height: 16px;
    }
}

@media (max-width: 979px) {
    .wrapper {
        margin-bottom: 20px;
        height: 40px;
    }

    .input {
        height: 40px;
    }
}
