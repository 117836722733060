/**/

.absoluteWrapper {
    position: absolute;
    z-index: 999999;
}

.wrapper {
    position: relative;
    width: 316px;
    background-color: var(--main-black);
    padding: 19px 10px 10px 19px;
    display: flex;
    border-radius: 4px;
    min-height: 149px;
    flex-direction: column;
}

.header {
    display: flex;
    margin-bottom: 13px;
    justify-content: space-between;
}

.title {
    font-size: 11px;
    font-weight: 500;
    color: var(--main-blue);
    letter-spacing: 0.3px;
    text-transform: uppercase;
}

.closeIcon {
    padding: 0;
    height: 18px;
    width: 18px;
}

.text {
    color: var(--main-light-gray-tooltip);
    font-size: 15px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 22px;
    margin-bottom: 10px;
}

.nextButton {
    height: 40px;
    width: 86px;
    align-self: flex-end;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0.3px;
    text-align: center;
    text-transform: uppercase;
}

.arrow {
    height: 20px;
    width: 14px;
    background-color: var(--main-black);
    position: absolute;
}

.rightArrow {
    top: 33%;
    right: 0;
    transform: translateX(14px);
    clip-path: polygon(100% 50%, 0 0, 0 100%);
}

.topArrow {
    top: 0;
    left: 32px;
    height: 14px !important;
    width: 20px !important;
    transform: translateY(-14px);
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

