.button {
    height: 40px;
    padding-left: 22px;
    padding-right: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 0 !important;
}

.transparentButton {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent !important;
    border: transparent !important;
}

.transparentButton:hover, .transparentButton:focus, .transparentButton:active {
    box-shadow: none;
    outline: none;
    border: transparent !important;
}

.transparentOutline {
    border-radius: 4px !important;
    border: 1px solid var(--main-transparent-gray-2) !important;
}

.transparentOutline:hover, .transparentOutline:focus, .transparentOutline:active {
    box-shadow: none;
    outline: none;
    border: 1px solid var(--main-transparent-gray-2) !important;
    color: var(--main-gray) !important;
}


.boxButton {
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 !important;
}

.yellowButton {
    color: var(--main-black);
    -webkit-text-fill-color: var(--main-black) !important;
    background-color: var(--main-yellow);
    font-family: Maax-Medium, "Maax-Regular", -apple-system, "Ubuntu", sans-serif;
    border:0;
}

.yellowButton:hover, .yellowButton:focus, .yellowButton:active {
    background-color: var(--main-yellow-dark) !important;
    border: 0 !important;
    color: var(--main-black) !important;
    box-shadow: none !important;
}

.grayButton {
    color: black;
    background-color: var(--main-transparent-gray-1);
    border:0;
}

.grayButton:hover, .grayButton:focus, .grayButton:active {
    background-color: var(--main-light-gray) !important;
    border: 0 !important;
    color: black !important;
    box-shadow: none !important;
}

.lightBlue {
    color: var(--main-blue);
    border-color: var(--main-transparent-blue);
    background-color: var(--main-transparent-blue);
    font-size: 15px;
    font-weight: 500;
}

.lightBlue.active {
    background-color: var(--main-blue) !important;
    border-color: var(--main-blue) !important;
    color: white !important;
    box-shadow: none !important;
}


.darkBlue {
    background-color: var(--main-blue) !important;
    border-color: var(--main-blue) !important;
    font-size: 15px;
}

.darkBlue:hover, .darkBlue:focus, .darkBlue:active {
    background-color: var(--main-blue) !important;
    border-color: var(--main-blue) !important;
    color: white !important;
    box-shadow: none !important;
}



.largeButton {
    height: 59px;
    border-radius: 5px;
}

.lightBlue:hover, .lightBlue:focus, .lightBlue:active {
    background-color: var(--main-blue) !important;
    border-color: var(--main-blue) !important;
    color: white !important;
    box-shadow: none !important;
}

.lightBlue:hover g, .lightBlue:focus g, .lightBlue:active g, .lightBlue.active g {
    stroke: #ffffff !important;
    transition: stroke 200ms ease-in-out;
}
