/**/
.wrapper {
    width: 445px;
    height: 458px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

.nextButton {
    width: 100%;
    height: 65px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0px;
    text-align: center;
    text-transform: uppercase;
}

@media(min-width: 980px) and (max-width: 1400px) {
    .wrapper {
        width:370px;
        height: 459px;
    }

    .nextButton {
        height: 64px;
        font-size: 13px;
    }
}

@media(max-width: 979px) {
    .wrapper {
        display: none;
    }
}
