.divider {
  border-left: 1px solid rgb(235, 237, 237);
  height: 12px;
  width: 1px;
}

.DateRangePicker {
  border-radius: 4px;
  border: 1px solid var(--main-transparent-gray-2);

  input::-webkit-input-placeholder {
    text-align: left;
    vertical-align: middle;
    padding-top: 0px;
  }

  .DayPickerNavigation_button__default {
    border: 0;
    box-shadow: none;
    outline: none;
  }
  .DateInput_fang {
    display: none;
  }

  .DayPickerNavigation_button {
    border: 0;
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
    &:hover {
      border: 0;
    }
    &:active {
      background-color: transparent;
    }
  }

  .CalendarMonth_caption {
    text-transform: capitalize;
  }

  .DateRangePickerInput_arrow {
    align-self: center;
  }

  .DateInput_input {
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0px;
    height: 38px;
    padding-left: 5px;
    line-height: 15px;
    color: var(--main-black);
  }

  .DateInput_input__focused {
    border-bottom: 2px solid var(--main-yellow);
  }

  .DateRangePickerInput {
    border: 0;
    display: flex;
  }



  .DateRangePicker_picker {
    top: 55px !important;
    left: -16px !important;

    box-shadow: inset 0px 10px 20px 0px rgba(0,20,27,0.07);
  }

  .DayPicker {
    border-radius: 0;
    border: 0;
    box-shadow: 0px 10px 20px 0px rgba(0, 20, 27, 0.07);
    width: 920px !important;
    &>div>div {
      width: 920px !important;
    }

    .DayPicker_focusRegion {
      box-shadow: inset 0px 10px 20px 0px rgba(0,20,27,0.07);
    }

    .DayPicker_transitionContainer {
      margin-left: 69px;
      height: 413px !important;
      margin-right: 138px;
      width: 835px !important;
    }

    .DayPicker_weekHeaders__horizontal {
      margin-left: 0;
      margin: auto;
    }

    .DayPicker_weekHeader {
      padding: 0 !important;
      left: 133px !important;
      top: 58px;
      &:last-child {
        left:500px !important;
      }
      .DayPicker_weekHeader_ul {
        margin: 0;
      }
      .DayPicker_weekHeader_li {
        width: 38px;
        line-height: 15px;
      }
    }
  }

  .CalendarMonthGrid {
    background: transparent;
  }

  .CalendarMonth {
    padding-left: 53px !important;
    padding-right: 53px !important;
    margin-top: 21px;

    .CalendarMonth_table {
      border-collapse: separate;
      border-spacing: 0px 15px;
    }

    .CalendarMonth_caption {
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0px;
      text-align: center;
      padding-bottom: 25px;
      padding-top: 0;
    }

    .CalendarDay {
      text-align: center !important;
      height: 34px !important;
      width: 38px !important;
      border: 0;

      &.CalendarDay__selected {
        background: white !important;
      }
      &:hover {

        &.CalendarDay__selected_span {
          background-color: var(--main-transparent-gray-2);
          border-radius: 0 !important;
          &::after {
            background-color: var(--main-transparent-gray-2);
          }
        }

        &.CalendarDay__selected_start, &.CalendarDay__selected_end {
          //background-color: var(--main-yellow);
          border-radius: 50% !important;
          &.CalendarDay__selected_end::before {
            left: 0;
          }
          &::after {
            content: "\00a0\00a0";
            position: absolute;
            top: 0;
            left: 2px;
            height: 34px;
            width: 34px;
            z-index: -10;
            background-color: var(--main-yellow);
            border-radius: 50%;
          }
          &::before {
            content: "\00a0\00a0";
            position: absolute;
            background-color: var(--main-transparent-gray-2);
            height: 34px;
            top: 0px;
            left: 20px;
            width: 50%;
            z-index: -20;
            overflow: hidden;
          }
        }

        border-radius: 50% !important;
        position: relative;
        background: transparent;
        z-index: 1000;
        &::after {
          content: "\00a0\00a0";
          position: absolute;
          top: 0;
          left: 2px;
          height: 34px;
          width: 34px;
          z-index: -6;
          background-color: transparent;
          border-radius: 50%;
          border: 2px solid var(--main-blue);
        }
      }
      &:last-child {
        text-align: center !important;
        padding-right: 0;
      }
    }


    .CalendarDay__selected_start, .CalendarDay__selected_end {
      border: 0;
      color: var(--main-black);
      border-radius: 50%;

      position: relative;
    }

    .CalendarDay__selected_start {
      z-index: 2000;
      &::after {
        content: "\00a0\00a0";
        position: absolute;
        top: 0;
        left: 2px;
        height: 34px;
        width: 34px;
        z-index: -10;
        background-color: var(--main-yellow);
        border-radius: 50%;
      }
      &::before {
        content: "\00a0\00a0";
        position: absolute;
        background-color: var(--main-transparent-gray-2);
        height: 34px;
        top: 0px;
        left: 20px;
        width: 50%;
        z-index: -20;
        overflow: hidden;
      }
    }

    .CalendarDay__selected_end.CalendarDay__selected_start {
      &::after {
        content: "\00a0\00a0";
        position: absolute;
        top: 0;
        left: 2px;
        height: 34px;
        width: 34px;
        z-index: -10;
        background-color: var(--main-yellow);
        border-radius: 50%;
      }
      &::before {
        content: "\00a0\00a0";
        position: absolute;
        background-color: var(--main-transparent-gray-2);
        height: 34px;
        top: 0px;
        left: 20px;
        width: 0;
        z-index: -20;
        overflow: hidden;
      }
      &:hover {
        &::before {
          width: 0;
        }
      }
    }

    .CalendarDay__selected_end {
      z-index: 2000;
      &::after {
        content: "\00a0\00a0";
        position: absolute;
        top: 0;
        left: 2px;
        height: 34px;
        width: 34px;
        z-index: -10;
        background-color: var(--main-yellow);
        border-radius: 50%;
      }
      &::before {
        content: "\00a0\00a0";
        position: absolute;
        background-color: var(--main-transparent-gray-2);
        height: 34px;
        top: 0px;
        left: 0px !important;
        width: 50%;
        z-index: -20;
        overflow: hidden;
      }
    }

    .CalendarDay__selected_span, .CalendarDay__hovered_span {
      background-color: var(--main-transparent-gray-2);
      color: var(--main-black);
      border: 0;
      &.CalendarDay__firstDayOfWeek {
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
      }
      &.CalendarDay__lastDayOfWeek {
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
      }
    }
  }


}

@media(min-width: 980px) and (max-width: 1400px) {
  .DateRangePicker {
    .DayPicker {
      width: 745px !important;
      &>div>div {
        width: 745px !important;
      }
      .DayPicker_transitionContainer {
        margin-left: 0px;
        height: 413px !important;
        margin-right: 0px;
        width: 745px !important;
      }

      .DayPicker_weekHeader {
        padding: 0 !important;
        left: 46px !important;
        top: 58px;

        &:last-child {
          left: 412px !important;
        }
      }
    }

    .CalendarMonthGrid__horizontal {
      left:0 !important;
    }

    .CalendarMonth {
      background: transparent;
      padding-left: 50px !important;
      padding-right: 50px !important;
      margin-top: 21px;
    }
  }

}

@media(max-width: 979px) {
  .DateRangePicker {
    .DayPicker {
      width: 510px !important;
      &>div>div {
        width: 510px !important;
      }
      .DayPicker_transitionContainer {
        margin-left: 0px;
        height: 413px !important;
        margin-right: 0px;
        width: 745px !important;
      }

      .DayPicker_weekHeader {
        padding: 0 !important;
        left: 46px !important;
        top: 58px;

        &:last-child {
          left: 412px !important;
        }
      }
      .DayPicker_weekHeader:first-child {
        padding: 0 !important;
        left: 50px !important;
        margin-left: 0 !important;
        top: 58px;

      }
    }
    .CalendarMonth {
      background: transparent;
    }
    .CalendarMonthGrid {
      margin: 0;
      width: 510px !important;
    }

    .DateInput {
      width: 110px;
    }
    .DateRangePickerInput_calendarIcon {
      padding: 0;
      margin: 0 0 0 10px;
    }
  }

}

.DayPickerNavigation_button__verticalDefault {
  &.DayPickerNavigation_prevButton__verticalDefault {
    padding-right: 85px;
    text-align: right !important;
  }
  &.DayPickerNavigation_nextButton__verticalDefault {
    padding-left: 85px;
    text-align: left !important;
  }
}

@media(max-width: 979px) {
  .DateRangePicker {
    .DateRangePickerInput {
      .DateRangePicker_picker {
        left: -9px !important;
        top: 49px !important;
      }
    }


    .DayPickerNavigation__verticalDefault {
      margin-bottom: 8px;
    }

    .DateInput {
      width: 94px;

      &:last-child {
        width: 104px;
      }
    }

    .DayPicker {
      width: 690px !important;

      & > div > div {
        width: 690px !important;
      }
      .DayPicker_transitionContainer {
        margin-left: 0px;
        margin-right: 0px;
        width: 690px !important;
      }

      .DayPicker_weekHeader:first-child {
        width: 100%;
        left: 0 !important;
        display: flex;
        justify-content: center;
        .DayPicker_weekHeader_li {
          width: 38px !important;
        }
      }

    }
    .CalendarMonthGrid {
      width: 100% !important;
      .CalendarMonth {
        padding: 0 !important;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

  }
}


@media (max-width: 767px) {
  .DateRangePicker {
    .DateRangePickerInput {
      .DayPicker {
        width: 510px !important;

        & > div > div {
          width: 510px !important;
        }
        .DayPicker_transitionContainer {
          margin-left: 0px;
          margin-right: 0px;
          width: 510px !important;
        }
      }
    }
  }
}


@media (max-width: 575px) {

  .DateRangePicker {
    .DateRangePickerInput {
      .DayPicker {
        width: calc(100vw - 30px) !important;

        & > div > div {
          width: calc(100vw - 30px) !important;
        }
        .DayPicker_transitionContainer {
          margin-left: 0px;
          margin-right: 0px;
          width: calc(100vw - 30px) !important;
        }
      }
    }
  }

}


/* DISABLED CONTROL */

.disabled {
  .DateRangePickerInput__disabled {
    background: transparent !important;
  }

  .DateRangePickerInput_calendarIcon {
    margin: 0 3px 0 15px;
    padding: 0;
  }

  .DateRangePicker {
    width: 100%;
    .DateInput_input {
      font-style: normal !important;
      background: transparent !important;
      font-size: 13px;
      font-weight: normal;
      letter-spacing: 0px;
      line-height: 15px;
      padding-left: 10px;
      padding-right: 5px;

    }
    .DateInput {
      width: 100px;
      background: transparent !important;

      &:nth-child(2) {
        margin-right: 15px;
      }
    }
  }
}
