.statusButton {
  background: var(--main-transparent-gray-3);
  border-radius: 5.23px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.37px;
  text-align: center;
  text-transform: uppercase;
}

.statusGray {
  color: var(--main-gray);
  background-color: var(--main-transparent-gray-3);
}

.statusRed {
  color: var(--main-red);
  background-color: var(--main-transparent-red);
}

.statusGreen {
  color: var(--main-green);
  background-color: var(--main-transparent-green);
}
