:root {
  --main-yellow: #f9e319;
  --main-yellow-dark: #f5ce00;
  --main-red: #e84a50;
  --main-transparent-yellow: #fefad6;
  --main-transparent-red: #faeaeb;
  --main-black: #00141b;
  --main-gray: #7f8c8d;
  --main-green: #00bb8b;
  --main-light-green: #b2eadc;
  --main-transparent-green: #e5f8f3;
  --main-blue: #00adbb;
  --main-light-blue: #b2e6ea;
  --main-transparent-blue: #e5f6f8;
  --main-transparent-gray-1: #dbdede;
  --main-transparent-gray-2: #e5e8e8;
  --main-transparent-gray-3: #f2f3f3;
  --main-transparent-gray-4: #f7f8f8;
  --main-light-gray-tooltip: #ebebeb;
  --main-light-gray: #a2abac;
}


body #root .green {
  color: var(--main-green) !important;
  -webkit-text-fill-color: var(--main-green) !important;
}

body #root .red {
  color: var(--main-red) !important;
  -webkit-text-fill-color: var(--main-red) !important;
}
