.footer {
  font-weight: normal;
  line-height: 22px;
  margin-bottom: 31px;
  margin-right: 70px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  color: var(--main-light-gray);
  font-size: 11px;
  bottom: 24px;
  height: 13px;
  letter-spacing: 0px;
}

@media (min-width: 980px) and (max-width: 1400px) {
  .footer {
    margin-bottom: 11px;
    margin-right: 13px;
  }
}

@media (max-width: 989px) {
  .footerDesktop {
    margin: 0 auto;
    height: auto;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 12vh;
  }
}
