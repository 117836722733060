/**/
.container {
    height: 50px;
    background-color: transparent;
    display: flex;
    align-items: center;
    border: 1px solid rgb(229, 232, 232);
    border-radius: 4px;
    position: relative;
}

.prefix {
    width: 50px;
    display: flex;
    justify-content: center;
}


.hover {
    top: -13px !important;
    left: 0px !important;
    background-color: white;
    padding: 5px 13px 8px 8px;
    font-size: 11px !important;
}

.error {
    color: var(--main-red);
    position: absolute;
}

.input[type="password"] {
    font-variant: normal;
    text-transform: none;
    letter-spacing: 2px;
}

.placeholder {
    position: absolute;
    pointer-events: none;
    color: var(--main-gray);
    font-size: 15px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 13px;
    left: 51px;
}

.input {
    border: 0;
    height: 48px;
    width: calc(100% - 50px);
    box-shadow: none;
}

.input:focus {
    outline: none;
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
}
