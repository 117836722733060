.wrapper {
    display: flex;
    align-items: center;
    position: relative;
}

.wrapper:hover {
    cursor: pointer;
}

.worldIcon {
    margin-right: 8px;
}

.arrowRight {
    margin-left: 7px;
}

.rotate {
    transform: rotate(-90deg);
}

.dropdown {
    position: absolute;
    padding: 16px;
    right: 0;
    top: 0px;
    color: var(--main-black);
    width: 148px;
    background-color: white;
    border-radius: 1px;
    box-shadow: 0px 10px 20px 0px rgba(0, 20, 27, 0.07);
    z-index: 2000;
}

.option {
    line-height: 16px;
    height: 16px;
    margin-bottom: 8px;
    font-size: 13px;
    display: flex;
    align-items: center;
}

.option:hover {
    font-weight: bold;
}

.option:last-child {
    margin-bottom: 0;
}

.outside {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vw;
    background: transparent;
    z-index: 1050;
}

.langCode {
    text-transform: capitalize;
}


@media(max-width: 979px) {
    .wrapper {
        /*display: none;*/
    }
}
