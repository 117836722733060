/**/
.modalWrapper {
    background-color: rgba(0, 20, 27, 0.8);;
}

.modalWrapper > div {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalContent {
    max-width: 355px;
    border-radius: 1px;
}

.modalBody {
    height: 277px;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.form {
    padding: 24px 24px 0 24px;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 43px;
}

.title {
    color: var(--main-black);

    font-weight: 500;
    letter-spacing: 0px;
    line-height: 23px;
    margin: 0;
    font-size: 18px !important;
}


.close {
    padding: 0;
    height: 10px;
    width: 10px;
    color: var(--main-black) !important;
}

.closeIcon {
    height: 12px;
    width: 12px;
}

.dateWrapper {
    position: relative;
    display: flex;
    justify-content: center;
}

.label {
    color: var(--main-gray);
    font-size: 11px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 13px;
    position: absolute;
    background-color: white;
    padding: 4px 15px 4px 6px;
}

.from{
    top: -11px;
    left: 43px;
}

.to {
    top: -11px;
    left: 160px;
}

.buttonRow {
    display: flex;
    height: 65px;
    width: 100%;
}

.icon {
    margin-right: 15px;
}

.divider {
    height: 65px;
    width: 1px;
    border-top: 18px solid var(--main-yellow);
    border-bottom: 18px solid var(--main-yellow);
    background-color: var(--main-black);
}

.submitButton {
    height: 65px;
    flex: 1;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.3px;
    text-align: center;
    text-transform: uppercase;
}
